@charset "UTF-8";

/*
#styleguide
.m-btn1

```
<a href="./contact/" class="m-btn1">
  <span class="btn-text">お問い合わせ</span>
</a>
```
*/
.m-btn1 {
  display: inline-block;
  position: relative;
  background-color: $color1;
  border: 2px solid $color1;
  color: #fff;
  @include f-size(15);
  text-align: center;
  @include bpTb {
    width: 100%;
    max-width: 500px;
  }
  @include bpSp {
    width: 80%;
  }
  &:hover {
    opacity: .8;
    text-decoration: none;
    transition-property: opacity;
    transition-duration: .2s;
    backface-visibility: hidden;
  }
  input[type=submit].m-btn1,
  input[type=button].m-btn1 {
    border: 2px solid $color1;
    border-radius: 3px;
  }

  // アイコン付
  .icon {
    padding-right: 5px;
    @include f-size(14);
    @include bpSp {
      @include f-size(17);
    }
  }
}

/*
#styleguide
.m-btn1

```
<a href="./about" class="m-btn2">
  <span class="brackets before"></span>
  <span class="text">宇野港土地100年の歴史を見る</span>
  <span class="brackets after"></span>
</a>
```
*/
.m-btn2 {
  display: inline-block;
  position: relative;
  padding: 15px;
  .brackets {
    position: absolute;
    top: 0;
    &.before {
      left: 0;
      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 14px;
        height: 14px;
      }
      &::before {
        top: 0;
        left: 0;
        border-top: 1px solid #555;
        border-left: 1px solid #555;
      }
      &::after {
        top: 43px;
        left: 0;
        border-bottom: 1px solid #555;
        border-left: 1px solid #555;
        @include bpSp {
          top: 39px;
        }
      }
    }
    &.after {
      right: 0;
      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 14px;
        height: 14px;
      }
      &::before {
        top: 0;
        right: 0;
        border-top: 1px solid #555;
        border-right: 1px solid #555;
      }
      &::after {
        top: 43px;
        right: 0;
        border-bottom: 1px solid #555;
        border-right: 1px solid #555;
        @include bpSp {
          top: 39px;
        }
      }
    }
  }
  .text {
    display: block;
    text-align: center;
  }
  &:hover {
    background-color: #ecf4f9;
    text-decoration: none;
  }

  // buttonの場合
  &.contact {
    padding: 0;
    .contact-btn {
      font-family: $mincho;
      @include f-size(15);
      padding: 16.5px;
      @include bpSp {
        @include f-size(13);
      }
    }
  }
}

/*
#styleguide
.m-btn3

```
<a href="/about/" class="m-btn3">
  <span class="text">MORE</span>
</a>
```
*/
.m-btn3 {
  position: relative;
  padding-left: 18px;
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 30%;
    left: 0;
    background-color: #000;
    transform: scale(1, 1);
    transform-origin: left top;
    transition: all .3s;
  }
  &::before {
    width: 10px;
    height: 1px;
    margin-top: 7px;
  }
  .text {
    position: relative;
    padding-bottom: 2px;
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #000;
      transform: scale(1, 1);
      transform-origin: left top;
      transition: all .3s;
    }
  }
  &::after {
    left: 0;
    width: 1px;
    height: 10px;
    margin: 3px 0 0 4.5px;
  }
  &:hover {
    text-decoration: none;
    &::before {
      transform-origin: left top;
      transform: scale(0, 1);
    }
    .text {
      &::after {
        transform-origin: left top;
        transform: scale(0, 1);
      }
    }
    &::after {
      transform-origin: left top;
      transform: scale(1, 0);
    }
  }

  //  白
  &.white {
    &::before,
    &::after {
      background-color: #fff;
    }
    .text {
      color: #fff;
      &::after {
        background-color: #fff;
      }
    }
  }
}


/*
#styleguide
.m-btn4

```
<a href="/about/" class="m-btn3">
  <span class="text">MORE</span>
</a>
```
*/
.m-btn4 {
  position: relative;
  padding-right: 30px;
  @include f-size(14);
  line-height: 1;
  @include bpSp {
    @include f-size(13);
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-image: url(../img/img_next.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 23px;
    height: 23px;
  }
  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }
}