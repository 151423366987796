@charset "UTF-8";

@import "./_setting";
@import "./_mixin";
@import "./module/_btn";
@import "./module/_title";
@import "./module/_other";

// header 背景
.l-header .glnv li.business {
  background-color: #fff;
}

// 事業内容
.page-business {
  padding-top: 80px;
  padding-bottom: 200px;
  @include bpPcs {
    padding-top: 55px;
    padding-bottom: 120px;
  }
  @include bpSp {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  // 一覧
  .business-text {
    padding-bottom: 40px;
    text-align: center;
  }
  .business-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    .business-item {
      position: relative;
      width: 47.5%;
      margin-bottom: 70px;
      @include bpPcs {
        margin-bottom: 40px;
      }
      @include bpSp {
        margin-bottom: 30px;
      }
      &-image {
        padding-bottom: 30px;
        @include bpPcs {
          padding-bottom: 20px;
        }
        @include bpSp {
          padding-bottom: 15px;
        }
      }
      &-number {
        padding-bottom: 15px;
        font-family: $Libre;
        @include f-size(20);
        line-height: 1;
        @include bpPcs {
          padding-bottom: 5px;
          @include f-size(18);
        }
        @include bpSp {
          @include f-size(15);
        }
      }
      &-title {
        padding-bottom: 15px;
        @include f-size(20);
        @include bpPcs {
          padding-bottom: 10px;
          @include f-size(18);
        }
        @include bpSp {
          @include f-size(15);
        }
      }
      &-text {
        padding-bottom: 70px;
        @include bpPcs {
          padding-bottom: 40px;
        }
        @include bpSp {
          padding-bottom: 30px;
        }
      }
      .business-btn {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}

// 詳細
.page-business {
  // ページ別余白
  &.rs,
  &.parking {
    padding-top: 80px;
    padding-bottom: 80px;
    @include bpPcs {
      padding-top: 55px;
    }
    @include bpSp {
      padding-top: 40px;
      padding-bottom: 45px;
    }
  }
  &.solar,
  &.food,
  &.culture {
    padding-top: 80px;
    padding-bottom: 300px;
    @include bpPcs {
      padding-top: 55px;
      padding-bottom: 185px;
    }
    @include bpSp {
      padding-top: 40px;
      padding-bottom: 45px;
    }
  }
  &.camp,
  &.hotspring {
    padding-top: 80px;
    padding-bottom: 440px;
    @include bpPcs {
      padding-top: 55px;
      padding-bottom: 225px;
    }
    @include bpSp {
      padding-top: 40px;
      padding-bottom: 45px;
    }
  }
  &.charter {
    padding-top: 80px;
    padding-bottom: 550px;
    @include bpPcs {
      padding-top: 55px;
      padding-bottom: 350px;
    }
    @include bpSp {
      padding-top: 40px;
      padding-bottom: 50px;
    }
  }
  &.single {
    .caption-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 40px;
      @include bpSp {
        padding-bottom: 30px;
      }
      &-image {
        width: 47.5%;
        @include bpSp {
          width: 100%;
          padding-bottom: 15px;
        }
      }
      &-textbox {
        width: 47.5%;
        @include bpSp {
          width: 100%;
        }
        .title {
          padding-bottom: 15px;
          @include f-size(20);
          @include bpPcs {
            padding-bottom: 10px;
            @include f-size(17);
          }
          @include bpSp {
            padding-bottom: 10px;
            @include f-size(16);
          }
          .subtext {
            display: block;
            @include f-size(14);
            @include bpPcs {
              @include f-size(13);
            }
            @include bpSp {
              @include f-size(12);
            }
          }
        }
        .text {
          padding-bottom: 15px;
          @include bpPcs {
            @include f-size(14);
          }
          @include bpSp {
            padding-bottom: 10px;
            @include f-size(13);
          }
        }
      }
    }
    .gallery-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      padding-bottom: 60px;
      @include bpPcs {
        padding-bottom: 35px;
      }
      @include bpSp {
        padding-bottom: 25px;
      }
      li {
        width: 31.4%;
        margin-right: 2.9%;
        padding-bottom: 2.9%;
        @include bpSp {
          width: 48%;
          margin-right: 2%;
          padding-bottom: 15px;
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
        &:nth-child(3n) {
          margin-right: 0;
          @include bpSp {
            margin-right: 2%;
          }
        }
      }
    }
    .business-list {
      &.single-page {
        .business-item {
          &-title {
            padding-bottom: 20px;
            @include bpPcs {
              padding-bottom: 10px;
            }
            .subtext {
              display: block;
              @include f-size(14);
              @include bpPcs {
                @include f-size(13);
              }
              @include bpSp {
                @include f-size(12);
              }
            }
          }
          &-text {
            padding-bottom: 0;
            @include bpPcs {
              padding-bottom: 0;
            }
            @include bpSp {
              padding-bottom: 0;
            }
          }
        }
      }
    }

    .caption {
      padding-bottom: 10px;
      @include f-size(17);
      @include bpSp {
        @include f-size(15);
      }
    }
    .parking-table {
      width: 100%;
      tr {
        th {
          width: 20%;
          @include bpPcs {
            width: 30%;
          }
        }
        td {
          width: 80%;
          @include bpPcs {
            width: 70%;
          }
          .more-text {
            padding-left: 5em;
            @include bpPcs {
              padding-left: 0;
              @include f-size(13);
            }
            @include bpSp {
              padding-left: 5em;
            }
          }
          .more-text2 {
            padding-left: 5em;
            @include bpPcs {
              padding-left: 0;
              @include f-size(13);
            }
            @include bpSp {
              padding-left: 5em;
              text-indent: -5em;
            }
          }
        }
      }
    }
    .caption-text {
      padding-bottom: 25px;
      @include bpSp {
        padding-bottom: 20px;
      }
    }
    .access-map {
      padding-bottom: 85px;
      line-height: 1;
      iframe {
        width: 100%;
        max-width: 1000px;
        height: 346px;
        @include bpPcs {
          max-width: 100%;
          height: 420px;
        }
      }
      .map-link {
        display: block;
        padding-top: 20px;
        @include f-size(12);
        text-align: right;
        text-decoration: underline;
        @include bpSp {
          padding-bottom: 15px;
          @include f-size(11);
        }
      }
    }

    .caption-list {
      &.solar {
        width: 35%;
        @include bpPcs {
          width: 100%;
        }
      }
      .title {
        @include f-size(20);
        margin-bottom: 20px;
        padding-bottom: 5px;
        border-bottom: 1px solid #000;
        @include bpPcs {
          @include f-size(18);
        }
        @include bpSp {
          margin-bottom: 15px;
          @include f-size(16);
        }
      }
    }

    // 電話
    .tel-business {
      padding-bottom: 60px;
      @include bpPcs {
        padding-bottom: 40px;
      }
      @include bpSp {
        padding-bottom: 30px;
      }
      .text {
        padding-bottom: 25px;
        line-height: 2em;
        text-align: center;
        @include bpSp {
          padding-bottom: 15px;
        }
      }
      .tel-wrapper {
        position: relative;
        width: 100%;
        max-width: 800px;
        margin-right: auto;
        margin-left: auto;
        padding: 55px 0;
        background-image: url(../img/bg_pattern1.jpg);
        @include bpPcs {
          padding: 40px $baseWidthPadding;
        }
        @include bpSp {
          padding: 30px $baseWidthPadding;
        }
      }
      .brackets {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        &.before {
          left: 0;
          &::before,
          &::after {
            content: "";
            position: absolute;
            width: 14px;
            height: 14px;
          }
          &::before {
            top: 0;
            left: 0;
            border-top: 1px solid #555;
            border-left: 1px solid #555;
          }
          &::after {
            bottom: 0;
            left: 0;
            border-bottom: 1px solid #555;
            border-left: 1px solid #555;
          }
        }
        &.after {
          right: 0;
          &::before,
          &::after {
            content: "";
            position: absolute;
            width: 14px;
            height: 14px;
          }
          &::before {
            top: 0;
            right: 0;
            border-top: 1px solid #555;
            border-right: 1px solid #555;
          }
          &::after {
            bottom: 0;
            right: 0;
            border-bottom: 1px solid #555;
            border-right: 1px solid #555;
          }
        }
      }
      .telbox {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 100%;
        max-width: 540px;
        margin-right: auto;
        margin-left: auto;
        z-index: 2;
        @include bpPcs {
          max-width: 450px;
        }
        @include bpSp {
          display: block;
          width: 100%;
        }
        .m-btn1 {
          width: 235px;
          padding: 13px;
          @include bpSp {
            display: block;
            width: 100%;
            max-width: 235px;
            margin-right: auto;
            margin-left: auto;
          }
        }
        &-link {
          display: inline-block;
          position: relative;
          padding-left: 35px;
          @include f-size(40);
          line-height: 1;
          @include bpPcs {
            @include f-size(28);
          }
          @include bpSp {
            display: block;
            width: 100%;
            max-width: 200px;
            margin-top: 20px;
            margin-right: auto;
            margin-left: auto;
            @include f-size(26);
          }
          &::before {
            font-family: "Font Awesome 5 Free";
            content: "\f095";
            @include f-size(24);
            font-weight: 900;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
          &:hover {
            opacity: 0.8;
            text-decoration: none;
          }
        }
      }
    }
  }
}